.banner {
  position: relative;
}

.banner_contents {
  color: #fff;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.8)
  );
  padding: 20rem 0rem 0rem 0rem;
}

.banner_title {
  font-size: 3rem;
  font-weight: 500;
  padding-left: 2rem;
  padding-right: 2rem;
}

.banner_description {
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 50%;
  max-width: 1200px;
  font-size: 1.25rem;
}

.banner_button {
  margin-left: 2rem;
  margin-top: 25px;
  padding: 0.5rem 3rem;
  background-color: rgba(25, 25, 25, 0.8);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 0.2vw;
  cursor: pointer;
  transition: all 0.3s ease;
}

.banner_button:hover {
  color: black;
  background-color: rgb(243, 243, 243);
}

.banner--fadeBottom {
  height: 7rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(25, 25, 25, 1),
    #111
  );
}

@media (max-width: 1200px) {
  .banner_description {
    width: 70%;
    max-width: 1200px;
  }
}
@media (max-width: 900px) {
  .banner_description {
    width: 100%;
    max-width: 1200px;
  }
}
@media (max-width: 800px) {
  .banner_contents {
    text-align: center;
  }
  .banner_description {
    font-size: 1rem;
  }
  .banner_button {
    margin-left: 0rem;
  }
}
@media (max-width: 580px) {
  .banner_description {
    font-size: 1rem;
  }
}
