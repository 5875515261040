.contact {
  padding: 15rem 0rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../img/banner-ex.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-container {
  background-color: rgba(0, 0, 0, 0.8);
  width: 40%;
  max-width: 1200px;
  border-radius: 2rem;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-container > h1 {
  justify-self: center;
}

.contact-icon {
  color: black;
}

.info {
  width: 100%;
  display: flex;
  padding: 2rem 0rem;
  align-items: center;
  justify-content: space-evenly;
  background-color: rgb(235, 235, 235);
  color: black;
  top: 0;
  overflow: hidden;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}

.contact-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info > p {
  font-size: 1rem;
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  align-self: center;
  padding-top: 2rem;
}
.name,
.email,
.phone,
.text,
.button {
  width: 60%;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  font-size: 1rem;
  margin-top: 1.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text {
  font-family: "Montserrat", sans-serif;
}

.button {
  background: -webkit-linear-gradient(180deg, #03fcb1, #04c595);
  font-weight: 500;
  margin-bottom: 10rem;
  cursor: pointer;
}

@media (max-width: 1310px) {
  .contact-container {
    width: 60%;
  }
}
@media (max-width: 820px) {
  .contact-container {
    width: 80%;
  }
}
@media (max-width: 585px) {
  .contact {
    padding: 8rem 0rem 0rem 0rem;
  }
  .contact-container {
    width: 100%;
    border-radius: 0;
  }
  .info {
    display: none;
  }
  .contact {
    padding: 0rem 0rem 0rem 0rem;
  }
  .title {
    margin-top: 5rem;
  }
}
@media (max-width: 460px) {
  .name,
  .email,
  .phone,
  .text,
  .button {
    width: 80%;
  }

  .title {
    padding-top: 5rem;
  }
}
