.testimony {
  padding: 0rem 2rem 2rem 2rem;
  border-top: 1px solid rgb(35, 35, 35);
}
.testimony_title_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.testimony_title {
  font-size: 2.5rem;
  color: #fff;
  font-weight: 400;
  padding-bottom: 5rem;
  text-align: center;
}

.testimony_reviews {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
}
.review {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
}
.stars {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.star {
  color: rgb(255, 236, 126);
  font-size: 1.5rem;
}

.review_description {
  width: 100%;
  padding-top: 10px;
  line-height: 1.5;
}

@media (max-width: 540px) {
  .testimony_reviews {
    display: flex;
    flex-direction: column;
  }
}
