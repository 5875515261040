.whatWeDo {
  padding: 0rem 2rem;
}

.whatWeDo_container {
  display: flex;
  align-items: center;
  position: relative;
}

.description_container {
  width: 50%;
  color: #fff;
}

.title {
  font-size: 2.5rem;
  font-weight: 500;
  padding-bottom: 1rem;
}

.description {
  width: 90%;
  max-width: 1200px;
  line-height: 1.5;
  font-size: 1.25rem;
}

.img_container {
  width: 50%;
  height: 50%;
  display: flex;
}

.img_container > img {
  width: 100%;
  margin: 5rem 0rem;
}

a {
  text-decoration: none;
  color: #fff;
}

.logo {
  display: inline-block;
  height: 500px;
  object-fit: contain;
  width: 100%;
}

.icon_container {
  padding-top: 20px;
  color: #fff;
  display: flex;
}

.icon {
  margin-right: 10px;
  cursor: pointer;
}

.big-logo {
  display: none;
}

@media (max-width: 1490px) {
  .whatWeDo_container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .big-logo {
    display: flex;
  }
  .img_container {
    width: 100%;
  }

  .description_container {
    width: 100%;
  }

  .logo {
    height: 350px;
  }
}

@media (max-width: 1490px) {
  .whatWeDo {
    padding-bottom: 0rem;
  }
}

@media (max-width: 900px) {
  .description {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .description_container {
    text-align: center;
  }
  .description {
    font-size: 1rem;
  }
  .icon_container {
    justify-content: center;
    align-items: center;
  }
  .img_container {
    flex-direction: column;
    margin-top: 5rem;
  }
  .img_container > img {
    margin-top: 1rem;
  }
}
@media (max-width: 460px) {
  .whatWeDo {
    margin-top: -50px;
  }
}

@media (max-width: 500px) {
  .big-logo {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 435px) {
  .icon_container {
    flex-direction: column;
  }
  .icons {
    display: flex;
    padding-bottom: 1rem;
  }
}
